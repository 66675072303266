<template>
  <div>
    <h2 class="font-weight-bolder mb-2" >
      <span @click="$router.go(-1)" >
        <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
          <feather-icon icon="ArrowLeftCircleIcon" size="35" />
        </b-button>
      </span> Crear forma de pago
    </h2>
    <b-card>
      <!-- form -->
      <validation-observer ref="createPayment">
        <b-form
            class="mt-2"
            @submit.prevent="handleSubmit"
            @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col cols="12">
              <validation-provider
                  #default="{ errors }"
                  :name="'Nombre'"
                  rules="required"
              >
                <b-form-group
                    :label="'Nombre'"
                    label-for="payment-name"
                >
                  <b-form-input
                      v-model="name"
                      name="name"
                      :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2 mr-1"
              >
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      required,
    }
  },
  methods: {
    ...mapActions({
      create: 'payments/create',
    }),
    handleSubmit() {
      this.$refs.createPayment.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ payment: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    createFormData() {
      const data = new FormData()

      data.append('name', this.name)

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
